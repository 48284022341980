<template>
    <Menu ref="menu" :theme="theme" :open-names="openName" :active-name="selectedSideMenuUuid" @on-select="onSelectMenu"
          width="auto" :accordion="accordion" :style="{height: '100%'}">
        <div v-for="(item,i) in currentSideMenu" v-bind:key="i">
            <MenuItem :ref="item.uuid" :name="item.uuid" :to="item.url" v-if="!item.children">
                <Icon :type="item.icon" />
                {{item.permissionName}}
            </MenuItem>
            <Submenu :name="item.uuid" v-if="item.children">
                <template slot="title">
                    <Icon :type="item.icon" />
                    {{item.permissionName}}
                </template>
                <MenuItem v-for="(subItem,j) in item.children"  v-bind:key="j" :ref="subItem.uuid" :name="subItem.uuid"
                    :to="subItem.url">{{subItem.permissionName}}</MenuItem>
            </Submenu>
        </div>
    </Menu>
</template>
<script>
    import { mapState } from "vuex";
    export default {
        name: "LeftMenu",
        props: {
            updateBreadcrumb: {
                type: Function,
                default: null
            }
        },
        data () {
            return {
                theme: 'light',
                accordion: true,
                openName: []
            }
        },
        computed:{
            ...mapState("menu", [
                "currentSideMenu",
                "allMenu",
                "selectedSideMenuUuid"
            ]),
        },
        created() {
            console.log("side load")
        },
        methods: {
            onSelectMenu(name){
                this.$store.dispatch("menu/selectedSide", name)
            },
        },
        watch: {
            selectedSideMenuUuid(){
                this.$nextTick(function () {
                    this.$refs.menu.updateOpened();
                    this.$refs.menu.updateActiveName();
                })
            }
        }
    }
</script>

<style>
</style>
