<template>
    <div class="layout">
        <Layout :style="{height: '100%'}">
            <Header>
                <Top></Top>
            </Header>
            <Layout :style="{overflow: 'hidden', flexDirection:'row'}">
                <Sider v-if="currentSideMenu && currentSideMenu.length > 0"  :style="{background: '#fff'}">
                    <LeftMenu :updateBreadcrumb="updateBreadcrumb"></LeftMenu>
                </Sider>
                <Layout :style="{padding: '0 24px'}">
                    <Breadcrumb :style="{margin: '14px 0'}">
                        <BreadcrumbItem v-for="(item, i) in menu" v-bind:key="i">{{item}}</BreadcrumbItem>
                    </Breadcrumb>
                    <Content :style="{padding: '24px', minHeight: '280px', background: '#fff', overflowY: 'auto' }">
                        <router-view/>
                    </Content>
                    <Footer :style="{padding: '10px 24px', textAlign: 'center'}">{{copyright}}</Footer>
                </Layout>
            </Layout>
        </Layout>
    </div>
</template>

<script>
    import LeftMenu from '/src/framework/LeftMenu.vue'
    import Top from '/src/framework/Top.vue'
    import { mapState } from "vuex";
    export default {
        name: 'Index',
        components:{
            LeftMenu,
            Top,
        },
        data () {
            return {
                copyright: this.config.copyright,
                menu: []
            }
        },
        computed:{
            ...mapState("menu", [
                "currentSideMenu"
            ]),
        },
        methods : {
            updateBreadcrumb(menu){
                if(Array.isArray(menu)){
                    this.menu = menu;
                }
            }
        }
    }
</script>

<style scoped>
    .layout{
        border: 1px solid #d7dde4;
        background: #f5f7f9;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        height: 100%;
    }
    /deep/ .ivu-layout-header{
        padding: 0 30px 0 10px;
        color: #fff;
    }
</style>
