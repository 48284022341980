<template>
    <Menu mode="horizontal" theme="dark" :active-name="selectedHeaderMenuUuid" @on-select="selectMenu">
        <div class="layout-logo">奇点科技</div>
        <div class="layout-nav layout-nav-custom">
            <a class="refresh" @click="refresh">
                <Icon type="ios-refresh-circle-outline" />
            </a>
            <div style="display: inline-block;" v-if="layoutNav" >
                <MenuItem :name="item.uuid" v-for="(item, index) in headerMenu" v-bind:key="index">
                    <Icon :type="item.icon"></Icon>
                    {{item.permissionName}}
                </MenuItem>
            </div>
        </div>
        <div class="layout-right">
            <div style="margin-right: 20px;display: inline-block">
                <Poptip v-model="visible" @on-popper-show="() => {visible = true}" @on-popper-hide="() => {visible = false}">
                    <div>
                        <span>{{currentOrg.name}}</span>
                        <Icon type="ios-arrow-down" class="ivu-ml" :style="{transform: visible ? 'rotate(180deg)' : '', transition: 'transform 0.2s ease-in-out'}"></Icon>
                    </div>
                    <div slot="content" class="org-dropdown">
                        <div class="org-tree">
                            <!--<Tree :data="orgTree" @on-select-change="selectChangeOrg"></Tree>-->
                            <el-tree :data="orgTree" default-expand-all :expand-on-click-node="false" @node-click="selectChangeOrg"> </el-tree>
                        </div>
                    </div>
                </Poptip>
            </div>
            <Dropdown @on-click="clickUserAvatar">
                <Avatar icon="ios-person" /><span class="username">{{username}}</span>
                <DropdownMenu slot="list">
                    <DropdownItem name="medifyPassword">修改密码</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <span class="logout" @click="logout">退出</span>
        </div>
    </Menu>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: "Top",
        data () {
            return {
                layoutNav : true,
                visible: false
            }
        },
        computed: {
            ...mapState({
                username: (state) => {
                    console.log(state);
                    return state.user.info.username
                }
            }),
            ...mapState("menu", [
                "headerMenu",
                "selectedHeaderMenuUuid"
            ]),
            ...mapState("org", [
                "currentOrg",
                "orgTree"
            ]),
        },
        methods:{
            logout(){
                this.$Modal.confirm({
                    title: '提示',
                    content: '确认要退出登录？？？',
                    onOk: () => {
                        this.get("/sys/logout").then(()=>{
                            this.$store.commit("user/clearInfo")
                            this.router.push("/login")
                        }).catch(e => {
                            console.log(e)
                        })
                    }
                })
            },
            clickUserAvatar(name){
                if("medifyPassword" === name){
                    console.log(this.router)
                    this.router.push("/medifyUserPassword")
                }
            },
            selectMenu(uuid){
                this.$store.dispatch("menu/selectedHeader", uuid)
            },
            selectChangeOrg(arr, node){
                this.visible = false
                console.log(arr, node)
                this.$store.dispatch("org/selectedOrg", node.data.uuid)
                this.$nextTick(() => {
                    this.$router.push({
                        path: '/refresh',
                    })
                })
            },
            refresh(){
                this.$router.push({
                    path: '/refresh',
                })
            }
        }
    }
</script>

<style>
    .layout-logo{
        color: #fff;
        line-height: 36px;
        font-size: 18px;
        height: 30px;
        border-radius: 3px;
        float: left;
        position: relative;
        top: 15px;
        left: 20px;
        min-width: 140px;
    }
    .layout-nav{
        margin: 0 auto;
        margin-left: 75px;
        display: inline-block;
    }
    .layout-right{
        display: inline-block;
        color: #fff;
        float: right;
    }
    .logout{
        color: #fff;
        margin-left: 20px;
        cursor: pointer;
    }
    .username{
        margin-left: 10px;
    }
    .refresh{
        display: inline-block;
        float: left;
        color: white;
        text-decoration: white;
    }
    .org-tree{
        max-height: 300px;
    }
</style>
